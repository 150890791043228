<script setup>
</script>

<template>
  <BModal ref="modal" title="Check Availability" size="lg" hide-footer>
    <div style="text-align: center;" class="pt-5 pb-5">
      <h3>£{{price}}*</h3>
      <h4>For your {{eventType}} on {{formattedDate}} in {{eventLocation}}.</h4>
      <p>Price based on Huzi performing up to 2 hours of close up magic.</p>
      <p>* Availability and Price estimates are <b>approximate</b>.</p>
      <h5>To confirm booking, please contact Huzi at <a href="mailto:huzimagic@gmail.com">huzimagic@gmail.com</a></h5>
    </div>
  </BModal>
</template>

<script>
import priceList from "@/data/price-list.json";

export default {
  name: "AvailabilityCheckerResultsModal",
  data() {
    return {
      eventType: null,
      eventLocation: null,
      eventDate: null,
    }
  },
  computed: {
    price() {
      let p = priceList.find(el => el[0] === this.eventLocation);
      if (!p) {
        return 'POA';
      }
      return p[1];
    },
    formattedDate() {
      return new Date(this.eventDate).toLocaleDateString('en-GB');
    }
  },
  methods: {
    show(eventType, eventLocation, eventDate) {
      this.eventType = eventType;
      this.eventLocation = eventLocation;
      this.eventDate = eventDate;
      this.$refs.modal.show();
    }
  }
}
</script>

<style scoped>

</style>
