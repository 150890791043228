<script setup>

</script>

<template>
  <BNavbar toggleable="lg" variant="light" >
    <BNavbarToggle target="nav-collapse" class="ml-auto" />
    <BCollapse id="nav-collapse" is-nav>
      <BContainer>
        <BNavbarNav class="mx-auto">
          <BNavItem :to="{ path: '/' }" active-class="active">Home</BNavItem>
          <BNavItem :to="{ path: '/about' }" active-class="active">Bio</BNavItem>
          <BNavItem :to="{ path: '/videos' }" active-class="active">Videos</BNavItem>
          <BNavItem :to="{ path: '/testimonials' }" active-class="active">Testimonials</BNavItem>
          <BNavItem href="https://www.instagram.com/huzimagician/" target="_blank">Instagram</BNavItem>
          <BNavItem :to="{ path: '/faqs' }" active-class="active">FAQ</BNavItem>
          <BNavItem :to="{ path: '/contact' }" active-class="active">Contact</BNavItem>
        </BNavbarNav>
      </BContainer>
    </BCollapse>
  </BNavbar>
</template>

<style >
.navbar-nav {
  text-align: center;
}
.navbar-nav > li{
  padding-left:10px;
  padding-right:10px;

}
</style>
