<script>
import NavigationBar from "@/components/NavigationBar.vue";
export default {
  name: 'PageHeader',
  components: {
    NavigationBar,
  }
}
</script>

<template>
  <div class="position-sticky header">
    <div class="header-container">
      <BContainer>
        <BRow class="justify-content-center">
          <div>
            <img class="logo mt-2 mb-2" src="@/assets/huzi-logo.jpg" alt="Huzi Logo"/>
            <p class="tagline">Close-up Magician for all your events</p>
          </div>

        </BRow>
      </BContainer>
    </div>
    <NavigationBar  />
  </div>
</template>

<style scoped>
.logo {
  max-width: 500px;
  width: 100%;
}
.header-container {
  background-color: black;
  z-index: 99999;
  width: 100%;
}

.header-container p {
  color: white;
  text-align: center;
}

.header {
  z-index: 1;
}

.tagline {
  font-size: 1.3em
}

</style>
