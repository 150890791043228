<script setup>

</script>

<template>
  <h2>Contact Huzi</h2>
  <BContainer class="text-center contact-info mt-4">
    <label for="email">Email</label>
    <a href="mailto:huzimagic@gmail.com"><p id="email">huzimagic@gmail.com</p></a>

    <label for="tel">Tel</label>
    <a href="tel:+447801419396"><p id="tel">07801419496</p></a>
  </BContainer>
</template>

<style scoped>
label {
  font-weight: bold;
  margin-bottom: 0;
}

.contact-info a {
  color: black;
}

</style>
