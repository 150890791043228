import { createApp } from 'vue'
import App from './App.vue'
import { configureCompat } from 'vue'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap-vue/dist/bootstrap-vue-icons.min.css'
import '@/assets/styles.css'
import { createRouter, createWebHistory } from 'vue-router'
import HomePage from "@/pages/HomePage.vue";
import AboutPage from "@/pages/AboutPage.vue";
import VideosPage from "@/pages/VideosPage.vue";
import TestimonialsPage from "@/pages/TestimonialsPage.vue";
import ContactPage from "@/pages/ContactPage.vue";
import FAQsPage from "@/pages/FAQsPage.vue";

const routes = [
    {path: '/', component: HomePage},
    {path: '/about', component: AboutPage},
    {path: '/videos', component: VideosPage},
    {path: '/testimonials', component: TestimonialsPage},
    {path: '/faqs', component: FAQsPage},
    {path: '/contact', component: ContactPage}
]

const router = createRouter({
    // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
    history: createWebHistory(),
    routes, // short for `routes: routes`
})

configureCompat({
    MODE: 2,
});

const app = createApp(App);
app.use(BootstrapVue);
app.use(BootstrapVueIcons);
app.use(router);
app.mount('#app');
