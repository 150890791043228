<script setup>
import eventLocations from "@/data/event-locations.json";
import AvailabilityCheckerResultsModal from "@/components/AvailabilityCheckerResultsModal.vue";
</script>

<template>
  <BCard bg-variant="dark" class="availability-checker" text-variant="light">
    <div v-if="state === 'ready'">
      <h3><BIconCheckCircleFill /> Check date & price instantly</h3>
      <BForm @submit.prevent="onSubmit">
        <BFormGroup class="mb-2">
          <BFormSelect :options="eventTypes" v-model="eventType"></BFormSelect>
        </BFormGroup>
        <BFormGroup class="mb-2">
          <BFormSelect :options="eventLocations" v-model="eventLocation"></BFormSelect>
        </BFormGroup>
        <BFormGroup>
          <BFormDatepicker :min="minDate" v-model="eventDate"></BFormDatepicker>
        </BFormGroup>
        <BButton type="submit" block variant="dark" :disabled="!checkEnabled">Check Now</BButton>
      </BForm>
    </div>
    <div v-else-if="state === 'unavailable'">
      <h3>Unavailable on {{unavailableDate}}</h3>
      <BForm @submit.prevent="onSubmitNewDate">
        <BFormGroup>
          <BFormDatepicker :min="minDate" v-model="eventDate"></BFormDatepicker>
        </BFormGroup>
        <BButton type="submit" block variant="dark" @click="onSubmit">Check a different date</BButton>
        <BRow class="mt-2">
          <BCol cols="6">
            <BButton block variant="dark" @click="editSearch">Edit Search</BButton>
          </BCol>
          <BCol cols="6">
            <BButton block variant="dark" @click="clearSearch">Clear Search</BButton>
          </BCol>
        </BRow>
      </BForm>
    </div>
  </BCard>
  <AvailabilityCheckerResultsModal ref="resultsModal" />
</template>

<script>

// import priceList from "@/data/price-list.json";

export default {
  name: 'AvailabilityChecker',
  data() {
    return {
      state: 'ready',
      eventType: null,
      eventLocation: null,
      eventDate: null,
      eventTypes: [
        {value: null, text: 'Your event type...', disabled: true},
        "Wedding",
        "Corporate Event",
        "Private Event",
        "Other"
      ],
      eventLocations: [

      ]
    }
  },
  methods: {
    onSubmit() {
      // this.$refs.resultsModal.show(this.eventType, this.eventLocation, this.eventDate);

    },
    clearSearch() {
      this.eventType = null;
      this.eventLocation = null;
      this.eventDate = null;
      this.state = 'ready';
    },
    editSearch() {
      this.state = 'ready';
    }
  },
  computed: {
    minDate() {
      return new Date();
    },
    checkEnabled() {
      return this.eventDate && this.eventType && this.eventLocation;
    },
    unavailableDate() {
      return 'todo';
    }
  }
}
</script>

<style scoped>
.availability-checker {
  max-width: 650px;
  margin-left: auto;
  margin-right: auto;
  background-image: url('@/assets/availability-checker-bg.jpg');
  background-size: cover;
}
h3 {
  text-align: center;
  font-size: 1.5em;
}
</style>
