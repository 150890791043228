<script setup>

</script>

<template>
  <BRow>
    <BCol cols="4" md="3" offset-md="1">
      <BImg src="/images/huzi-headshot.jpg" class="headshot" style="width: 100%;"></BImg>
    </BCol>
    <BCol cols="8" md="6" offset-md="2">
      <div class="text-center quote">
        <BIconStarFill v-for="x in [1,2,3,4,5]" v-bind:key="x"/>
        <blockquote>"Simply the best close&#8209;up Magician we've ever seen." <span>Cheshire Life</span></blockquote>
      </div>

    </BCol>
  </BRow>
</template>

<style scoped>
.headshot {
  width: 100%;
  min-width: 250px;
  object-fit: contain;
}

.quote {
  font-size: 40px;
  white-space: break-spaces;
  line-height: 48px;
}
.quote span {
  font-size: 28px;
  display: block;
}
.quote .b-icon {
  font-size: 25px;
}

@media screen and (max-width: 800px) {
  .quote {
    font-size: 30px;
    line-height: 36px;
  }

  .quote span {
    font-size: 20px;
    display: block;
  }

  .quote .b-icon {
    font-size: 18px;
  }
}
</style>
