<script setup>

</script>

<template>
  <BCard class="testimonial">
    <BContainer flex class="p-0">
      <BAvatar size="lg" style="vertical-align: top; " src="/images/testimonial-avatar.jpg"/>
      <div class="heading" style="display: inline-block;">
<!--        <h3 class="mb-0">Everyone is talking about you!</h3>-->
        <BIconStarFill v-for="x in [1,2,3,4,5]" v-bind:key="x"/><br />
        <small v-html="subheading"></small>
      </div>
    </BContainer>
    <p class="mt-3">{{testimonial.testimonial}}</p>
  </BCard>
</template>

<script>
export default {
  name: 'TestimonialCard',
  props: {
    testimonial: {
      type: Object,
      required: true
    }
  },
  computed: {
    subheading() {
      return `${this.testimonial.name} booked for their ${this.testimonial.occasion} &bull; ${this.testimonial.date}`
    }
  }
}
</script>

<style scoped>
.testimonial h3 {
  font-size: 1.2em;
}

.heading {
  margin-left: 10px;
}
</style>
