<script setup>

</script>

<template>
  <BRow class="align-items-center mt-4 mb-4 text-center">
    <BCol cols="12" md="6" offset-md="3">
      <iframe
          class="video"
          src="https://player.vimeo.com/video/365303320?h=351b065fd7&autoplay=1&muted=1&title=0&byline=0&portrait=0"
          frameborder="0" allow="autoplay; fullscreen; picture-in-picture; muted; playsinline"
          allowfullscreen></iframe>
      <BLink class="more-videos-link" :to="{ path: '/videos' }">More Videos</BLink>
    </BCol>
  </BRow>

</template>

<style scoped>
.video {
  aspect-ratio: 16 / 9;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

.more-videos-link {
  color: black;
  font-size: 1.2em;
  font-weight: normal;
}

</style>
