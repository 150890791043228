<script setup>

</script>

<template>
  <h2>About Huzi</h2>
  <p>When I was 9 years old, a fellow martial arts student showed me a card trick after our lesson. It was the first trick I ever saw that was actually good. I was expecting the typical, boring, cringey routine your uncle would show you at family gatherings, but it wasn't.....it was actually really mind blowing. I had no idea that magic tricks could be that interesting.</p>

  <BImg src="/images/Black background suit.jpg"  center class="m-4 image-portrait" />

  <p>This student was 16 at the time and had already become somewhat of a role model to me, so when he did this trick, it really excited me. Maybe I wanted to copy the trick, maybe I just wanted to copy him...but regardless of why, I really, really wanted to understand how that trick worked so I could perform it too.</p>

  <p>After a lot (probably an unhealthy amount) of time sitting on my bed with a deck of cards, I managed, somehow, to work it out.<br />
  I practiced it. I tried to imitate the presentation I'd seen in my role model. I then tried it out at school and the reactions were the same as mine when I first saw it. I'd given someone else what my role model gave me.</p>

  <BImg src="/images/vlcsnap-2023-02-26-15h40m19s358.png"  center class="m-4 image-landscape" />

  <p>I remember this being quite a profound life moment because I not only felt this tremendous sense of accomplishment from all my thought and practice, but I became instantly addicted to giving people that feeling; the feeling of wonder. Regardless of age, gender, ethnicity, religious or spiritual background, it doesn't matter who you are, this type of magic makes us all momentarily question the nature of reality.</p>

  <p>It turned into a full-blown passion. I read magic books, had magic sets and became a huge fan of David Blaine and his performance style.<br />
  By pure coincidence, I happened to have a relative that was a very popular and successful stage Magician in Blackpool called Richard De Vere who my mum introduced me to, which opened up a whole world of knowledge and experience for me.</p>

  <BImg src="/images/vlcsnap-2023-02-26-15h41m08s282.png"  center class="m-4 image-landscape" />

  <p>After about a year I had a reputation in high school for being this crazy magician. I was already referred to as 'Hughesey/Huzi' (because my name's Hughes,) but I also started being called 'Magic Man' or 'Magic Sam' by teachers and students alike.</p>

  <p>After I got my degree in Business Management, I had this rather bleak period that a lot of post-graduates experience, where I was out of education and didn't know what I wanted to do with my life. Magic had always been something I'd considered a hobby and nothing more, but during this time I found myself looking back through my school year book and was reminded that about 85-90% of the messages said things like "keep doing the magic" or "don't waste your talent."
  That's when I decided it was time to listen to the messages and make this more than just a hobby.</p>

  <BImg src="/images/vlcsnap-2023-02-26-15h43m37s870.png"  center class="m-4 image-landscape" />

  <p>Since that decision, I've been captivating audiences of all ages with my close-up magic as a working professional Magician.<br />
  Since Richard De Vere passed away in 2014, it's motivated me more than ever to keep the magic alive. I've performed to multiple celebrities and worked big corporate events for business giants such as Apple and Coca-Cola. I work a lot of weddings to socially enhance the day by making it even more exciting, different and memorable than it'll already be. Professionalism is always at the heart of my work.....and I absolutely love doing it!</p>

  <BImg src="/images/vlcsnap-2023-02-26-15h44m08s562.png"  center class="m-4 image-landscape" />

  <p>If you want real world feedback direct from my past clients, <router-link :to="{path: '/testimonials'}">click here</router-link>.<br />
  I have videos of me in action that you can check out here and even more on my Instagram.</p>

  <p>See you at your next event!<br />
  <b>Huzi</b></p>

  <BImg src="/images/vlcsnap-2023-02-26-15h49m16s818.png"  center class="m-4 image-landscape" />

</template>

<style scoped>
.image-portrait {
  max-width: 300px;
  width: 100%;
}
.image-landscape {
  max-height: 300px;
  width: 100%;
}
</style>
