<template>
  <BContainer fluid class="p-0">
    <PageHeader class="page-header"/>
    <BContainer class="mt-4">
      <router-view></router-view>
    </BContainer>
    <PageFooter />
  </BContainer>
</template>

<script>
import PageHeader from './components/PageHeader.vue';
import PageFooter from "@/components/PageFooter.vue";
export default {
  name: 'App',
  components: {
    PageFooter,
    PageHeader
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.page-header {
  position: sticky;
  top: 0;
}

body, html {
  margin: 0;
}
</style>
