<script setup>

import TestimonialCard from "@/components/TestimonialCard.vue";
import testimonials from '@/data/testimonials.json';

</script>

<template>
  <h2>Client Feedback</h2>

  <BContainer class="testimonials">

      <BRow class="pl-3 pr-3 pb-3">
        <BCol class="mt-3" xs="12" lg="6" v-for="(testimonial, idx) in testimonials" v-bind:key="idx">
          <TestimonialCard :testimonial="testimonial" />

        </BCol>
      </BRow>

  </BContainer>

</template>

<script>
export default {
  name: 'TestimonialsPage',

}
</script>

<style scoped>
.testimonials {
  background-color: grey;
}
</style>
