<script>

import VideosTestimonial from "@/components/VideosTestimonial.vue";

export default {
  name: 'VideosPage',
  components: {
    VideosTestimonial

  },
  data() {
    return {
      videos: [
        { title: 'Promo', videoUrl: '', imageUrl: 'https://picsum.photos/300/200/?image=54'},
        { title: 'The Envelope', videoUrl: '', imageUrl: 'https://picsum.photos/300/200/?image=23'},
        { title: 'Films', videoUrl: '', imageUrl: 'https://picsum.photos/300/200/?image=25'},
        { title: 'Polos', videoUrl: '', imageUrl: 'https://picsum.photos/300/200/?image=36'},
        { title: 'Ghost Cards', videoUrl: '', imageUrl: 'https://picsum.photos/300/200/?image=34'},
        { title: 'Music', videoUrl: '', imageUrl: 'https://picsum.photos/300/200/?image=15'},
        { title: 'Emergency Cash', videoUrl: '', imageUrl: 'https://picsum.photos/300/200/?image=12'},
        { title: 'Invisible Coin', videoUrl: '', imageUrl: 'https://picsum.photos/300/200/?image=10'},
        { title: 'Inside', videoUrl: '', imageUrl: 'https://picsum.photos/300/200/?image=22'},
      ],
      chosenVideo: null,
    }
  },
  methods: {
    openVideo(video) {
      this.chosenVideo = video;
      this.$refs.videoModal.show();
    },
    closeModal() {
      this.$refs.videoModal.hide();
      this.chosenVideo = null;
    }
  }
}
</script>

<template>
  <h2>Videos</h2>

  <BContainer fluid class="p-4 mt-2 mb-4 text-center">
    <BRow>
      <BCol cols="4" class="p-0" v-for="video in videos" v-bind:key="video.title">
        <BCard overlay :img-src="video.imageUrl" text-variant="white" class="video-card " @click="() => openVideo(video)">
          <BCardBody class=" d-flex justify-content-center  pl-0 pr-0" >
            <BCardTitle class="overlay-text align-items-center ">{{ video.title }}</BCardTitle>
          </BCardBody>
        </BCard>
      </BCol>
    </BRow>
  </BContainer>
  <VideosTestimonial />

  <BModal ref="videoModal" centered body-bg-variant="dark" header-text-variant="light" header-bg-variant="dark" hide-footer hide-header size="lg">
    <BRow v-if="chosenVideo">
      <BCol cols="12" class="text-right">
        <BIconXLg  role="button" class="text-white" @click="() => this.closeModal()"/>
      </BCol>
      <BCol class="mt-2" cols="12">
<!--        <BImg :src="this.chosenVideo.imageUrl" />-->
        <iframe
            class="video"
            src="https://player.vimeo.com/video/365303320?h=351b065fd7&autoplay=1&muted=1&title=0&byline=0&portrait=0"
            frameborder="0"
            allowfullscreen></iframe>
      </BCol>
    </BRow>

  </BModal>

</template>

<style scoped>
.overlay-text {
  font-weight: bolder !important;
  font-size: 2.5vw;
  word-break: break-word;
}
.video {
  aspect-ratio: 16 / 9;
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
}

@media screen and (min-width: 1600px) {
  .overlay-text {
    font-size: 30px;
  }
}

@media screen and (max-width: 900px) {
  .overlay-text {
    font-size: 16px;
  }
}

</style>

<style>


.video-card .card-img-overlay {
  background-color: rgba(0, 0, 0, 0.5);
}

.video-card .card-img-overlay:hover {
  background-color: rgba(0, 0, 0, 0.7);
  cursor: pointer;
}
</style>
