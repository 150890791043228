<script setup>

</script>

<template>
<BContainer class="mb-4 mt-4 text-center">
  <small>Copyright Huzi &copy; 2023</small>
</BContainer>
</template>

<style scoped>

</style>
