<script setup>

</script>

<template>
  <h2>Frequently Asked Questions</h2>
  <BContainer>
    <p>If you're using some of your budget on me, you want to know exactly why you're doing that and why it's worth it, especially if it's your wedding and it's a one time thing that you want to be perfect, so let's go through it.</p>

    <h3 class="mt-5">What will you do at my event?</h3>
    <p>Don't worry. There's no childish wands, no cringey top hats, no suspicious props that scream "magician's special prop" that look like nothing you've ever seen in your life. I'm the polar opposite of that.
      I've been doing magic since I was 9 years old (with David Blaine being my main inspiration through high school) and I've been a working professional for over a decade. I perform modern, mind blowing, commercial close-up magic that really connects with and engages the audience. It's all about a normal looking guy (I'd like to think) doing crazy things with normal, everyday objects and situations. </p>
    <p>My act has a large variety of effects from things with playing cards to borrowed objects like rings or money. There's visual surprises of the elements like fire and smoke and routines that use the environment around me. It has this David Blaine/Dynamo type of organic street magic feel to it with some Derren Brown style undertones, giving it that sophistication. I mix and mingle or approach tables, and just absolutely astound people. More often than not this becomes the focal point of that period and a lot of clients tell me that I'm still a huge talking point as the event continues after my act.</p>
    <p>Every routine I do has got me bookings, so I know they all work. I've tried and tested many tricks and routines since starting magic professionally and most of it I've scrapped, so what you're getting here is decades of natural selection. The audience feedback has led me to this act, so it's guaranteed to amaze your guests because they were picked by them, not me.</p>
    <p>I also have a special wedding routine specifically aimed at the newly weds, which I'm particularly proud of because it gives them a really symbolic souvenir and always goes down incredibly well.</p>
    <p>All my routines are perfect ice breakers because they give your guests something exciting to discuss together, which is something they can often feel awkward about, especially if they haven't met one another before.</p>

    <h3 class="mt-5">How long will your act be? </h3>
    <p>My usual performance duration is 2 hours, which is what my estimated fee assumes. I sometimes do less or more time depending on the nature of the event. The location also plays a part of this as my fees are also based on the practicality of travel and potential accommodation.<br />
      If you require me for less or more than 2 hours, <router-link :to="{path: '/contact'}">contact me here</router-link> for a quote.</p>

    <h3 class="mt-5">How will you come across as a person to my guests?</h3>
    <p>Because I've been doing magic for so long, I don't need to focus on the magic because it's second nature to me. Instead, I focus on my audience.
      I'm always very polite and sociable when I approach groups and I observe the way they're interacting so I can adapt to it and have a laugh with them. I've performed to so many different types of people so once I understand who I'm performing to, I then also understand how to interact with them in a way that makes them enjoy themselves and feel comfortable.</p>
    <p>By the time I'm done, they're asking for a business card or approaching me later on for more magic. Sometimes they even want a picture with me. So rest assured my interaction with your guests is also backed up with years of experience.</p>

    <h3 class="mt-5">What about children?</h3>
    <p>I'm CRB checked and have three children of my own of varying ages so although I'm not a children's magician, I've got kids covered. I know kids and I know what makes them tick.
      Weddings usually have every age group in them and the child focused routines I have in my repertoire will amaze the adults as well as the children.</p>

    <h3 class="mt-5">What do you need from clients?</h3>
    <p>On the day of your event, I'm completely self sufficient and won't need catering for in any way.
      However around two weeks before your event I'll give you a call so we can chat about the finer details for the day and the role I play in it.</p>
    <p>Whether your event is a wedding, a corporate function or a private party, this is very important so I know what to expect at your event, as this can influence how I approach my act. It also gives me a clear understanding of what the event's primary focus is.
      For example, at weddings it's always important that I don't take the spotlight off the newly weds. It's your special day and that's where the emphasis needs to be. So chatting to you about your vision for the day really helps me plan everything on my end and reduces any stress for you.
      I'll also ask you for the best person to contact on the day of the event so I can let them know when I've arrived. </p>

    <h3 class="mt-5">What exactly am I buying?</h3>
    <p>From all my professional experience, I've learnt that I can't just turn up at your event and do the gig. A lot of what you're paying for is behind the scenes so to speak.</p>
    <p>After booking me and receiving your invoice we'll continue communicating whenever necessary and I'll provide any insurance documentation you might need. After we've had our pre-event chat (mentioned above) it's at this stage I'll research your venue and have a good look at exactly where I'll be performing so I can tailor my act to suit the specifications of that environment. I'll also call the venue and speak to the relevant staff so we all know what we're doing and there aren't any conflicts of interest on the day.</p>
    <p>As the event draws closer, I'll start preparing my act. I can't go into specific details here (sorry, it's a secret!) but it takes time, money and precision. I'll be working at home, extensively, making sure everything's perfect for your event.
      This work isn't something my clients ever see, they only see the results, but I think clients would be equally as astonished if they saw the lengths I go to before their event.</p>
    <p>I'll arrive at the venue early, where I'll let your chosen contact know I've arrived. I'll introduce myself to the staff, have a final chat with them and start planning my act so I'm ready to blow minds on the dot!</p>
  </BContainer>
</template>

<style scoped>
h3 {
  text-align: center;
  font-size: 1.3em;
}
</style>
